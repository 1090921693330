// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-index-js": () => import("./../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mixes-index-js": () => import("./../../src/pages/mixes/index.js" /* webpackChunkName: "component---src-pages-mixes-index-js" */),
  "component---src-pages-physicals-index-js": () => import("./../../src/pages/physicals/index.js" /* webpackChunkName: "component---src-pages-physicals-index-js" */),
  "component---src-pages-releases-index-js": () => import("./../../src/pages/releases/index.js" /* webpackChunkName: "component---src-pages-releases-index-js" */),
  "component---src-pages-samplers-index-js": () => import("./../../src/pages/samplers/index.js" /* webpackChunkName: "component---src-pages-samplers-index-js" */),
  "component---src-templates-audio-post-js": () => import("./../../src/templates/audio-post.js" /* webpackChunkName: "component---src-templates-audio-post-js" */),
  "component---src-templates-event-post-js": () => import("./../../src/templates/event-post.js" /* webpackChunkName: "component---src-templates-event-post-js" */),
  "component---src-templates-legal-page-js": () => import("./../../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-physical-post-js": () => import("./../../src/templates/physical-post.js" /* webpackChunkName: "component---src-templates-physical-post-js" */),
  "component---src-templates-tags-js": () => import("./../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

